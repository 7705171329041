import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar.js";
import ButtonServiceList from "../components/ButtonServiceList.js";
import InputSearchService from "../components/InputSearchService.js";
import NonAuthPage from "./NonAuthPage";
import { useNavigate } from "react-router-dom";
import { textTitleMain } from "../consts/ConstSevicePage";
import { getServicePriceList } from "../functions/api/getServicePriceList.js";
import ButtonServiceData from "../components/ButtonServiceData.js";

const ServicePage = ({ authStatus }) => {
  const languageCode =
    window?.Telegram?.WebApp?.initDataUnsafe?.user?.language_code === "ru"
      ? "ru"
      : "en";
  const navigate = useNavigate();
  // видимость кнопок скролла
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [isScrollDownVisible, setIsScrollDownVisible] = useState(true);

  const localStorageServiceList = localStorage.getItem("easehow_serviceList");
  let defaultServiceList = localStorageServiceList
    ? JSON.parse(localStorageServiceList)
    : process.env.NODE_ENV === "development"
    ? ButtonServiceData
    : {};
  const [serviceList, setServiceList] = useState(defaultServiceList);

  useEffect(() => {
    if (authStatus === "success") {
      (async () => {
        let serviceList = (await getServicePriceList(languageCode))
          .serviceCosts;
        if (serviceList) {
          localStorage.setItem(
            "easehow_serviceList",
            JSON.stringify(serviceList)
          );
          setServiceList(serviceList);
        }
      })();
    }
  }, [authStatus]);

  if (authStatus === "wait") {
    // #TODO Экран загрузки
    return <div>...</div>;
  }
  if (authStatus === "noauth") {
    return <NonAuthPage />;
  }

  if (authStatus === "error") {
    navigate("/error");
    return <div></div>;
  }

  let scrollTarget = document.querySelector(".welcome_content");

  function handleScrollButtonUp() {
    let scrollTarget = document.querySelector(".welcome_content");
    scrollTarget.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function handleScrollButtonDown() {
    let scrollTarget = document.querySelector(".welcome_content");
    scrollTarget.scrollTo({
      top: scrollTarget.scrollHeight - scrollTarget.offsetHeight,
      behavior: "smooth",
    });
  }

  function placeScrollbar(scrollTarget) {
    const scrollbar = document.querySelector(".scrollbar");
    const headerHeight = document.querySelector(".header").clientHeight;
    const footerHeight = document.querySelector(".welcome_footer").clientHeight;
    const scrollSize = scrollbar.offsetHeight;
    let scrollPosition =
      scrollTarget.scrollTop /
      (scrollTarget.scrollHeight - scrollTarget.offsetHeight);
    let visibleHeight =
      document.documentElement.clientHeight -
      headerHeight -
      footerHeight -
      scrollSize;

    scrollbar.style.top = `${scrollPosition * visibleHeight}px`;
  }

  // function handleScroll(event) {
  //   placeScrollbar(event.target);
  //   let scrollButtonVisibility = { top: false, bottom: false };
  //   if (event.target.scrollTop <= 20) {
  //     scrollButtonVisibility.bottom = true;
  //   } else {
  //     scrollButtonVisibility.top = true;
  //   }
  //   if (scrollButtonVisibility.top !== isScrollUpVisible) {
  //     setIsScrollUpVisible(scrollButtonVisibility.top);
  //   }
  //   if (scrollButtonVisibility.bottom !== isScrollDownVisible) {
  //     setIsScrollDownVisible(scrollButtonVisibility.bottom);
  //   }
  // }

  return (
    <div class="container">
      <div className="header">
        <h1>{textTitleMain}</h1>
      </div>
      <div class="scroll_wrapper">
        {/* <div class="scrollbar"></div> */}
        <InputSearchService />
        <div class="welcome_content">
          <ButtonServiceList serviceList={serviceList} />
        </div>
        <button
          className="tos_button"
          onClick={() => {
            navigate("/tos", {
              state: {
                prevPath: "/",
                prevState: {},
              },
            });
          }}
        >
          Terms of service
        </button>
      </div>
      <div class="welcome_footer">
        <NavBar currentPagePath={"/"} />
      </div>
    </div>
  );
};

export default ServicePage;
