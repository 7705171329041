import "./styles/index.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { TwaAnalyticsProvider } from "@tonsolutions/telemetree-react";
import ServicePage from "./pages/ServicePage";
import ErrorPage from "./pages/ErrorPage";
import ProfilePage from "./pages/ProfilePage";

import { Helmet } from "react-helmet";

import { authMiniApp } from "./functions/api/authMiniApp";
import WorkerListPage from "./pages/WorkerListPage";
import WorkerPage from "./pages/WorkerPage";
import ScrollToTop from "./components/ScrollOnTop";
import TOSPage from "./pages/TOSPage";

const App = () => {
  const [authStatus, setAuthStatus] = useState("wait");
  useEffect(() => {
    (async () => {
      if (window?.Telegram?.WebApp?.initData) {
        let responce = await authMiniApp(window.Telegram.WebApp.initData);
        let status = responce?.success ? "success" : "error";
        if (status === "success") {
          window?.Telegram?.WebApp?.expand();

          // Блокировка свайпа для тг
          const data = JSON.stringify({
            allow_vertical_swipe: false,
          });
          window.TelegramWebviewProxy.postEvent(
            "web_app_setup_swipe_behavior",
            data
          );
        }
        console.log("authResponce", responce);
        console.log("authStatus inside", status);
        setAuthStatus(responce?.success ? "success" : "error");
        if (responce?.success) {
          window.Telegram.WebApp.requestWriteAccess((isWritable) => {
            console.log("isWritable", isWritable)
          })
        }
      } else {
        setAuthStatus("noauth");
      }

      if (process.env.NODE_ENV === "development") {
        setAuthStatus("success");
      }
    })();
  }, []);

  console.log(
    "process.env.REACT_APP_YANDEX_METRIKA",
    process.env.REACT_APP_YANDEX_METRIKA
  );

  console.log(
    "process.env.REACT_APP_BACKEND_DOMAIN",
    process.env.REACT_APP_BACKEND_DOMAIN
  );

  return (
    <div>
      <Helmet>
        {process.env.REACT_APP_YANDEX_METRIKA && (
          <script type="text/javascript">
            {`(function (m, e, t, r, i, k, a) {
              m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
              m[i].l = 1 * new Date();
              for (var j = 0; j < document.scripts.length; j++) { if (document.scripts[j].src === r) { return; } }
              k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
              })
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

              ym(97521188, "init", {
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true
              });`}
          </script>
        )}
      </Helmet>
      <TwaAnalyticsProvider
        projectId={process.env.REACT_APP_TWA_PROJECT_ID}
        apiKey={process.env.REACT_APP_TWA_API_KEY}
        appName={process.env.REACT_APP_TWA_APP_NAME}
      >
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/worker" element={<WorkerPage />} />
            <Route path="/list" element={<WorkerListPage />} />
            <Route path="/tos" element={<TOSPage></TOSPage>} />
            <Route path="/" element={<ServicePage authStatus={authStatus} />} />
          </Routes>
        </Router>
      </TwaAnalyticsProvider>
    </div>
  );
};

export default App;
