import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import WorkerCard from "../components/WorkerCard";
import styles from "../styles/WorkerListPage.module.css";
import {
  textFiat,
  textCript,
  textRate,
  textDeals,
  textPricePerHour,
} from "../consts/ConstSevicePage";
import { getWorkerList } from "../functions/api/getWorkerList";
import NavBar from "../components/NavBar";

const WorkerListPage = ({}) => {
  const languageCode = window?.Telegram?.WebApp?.initDataUnsafe?.user?.language_code === "ru" ? "ru" : "en";
  const navigate = useNavigate();
  const { state } = useLocation();
  const { serviceName } = state;

  const [currentSortValue, setCurrentSortValue] = useState("cript");
  const [currentSortDirection, setCurrentSortDirection] = useState(1); // 1 по возрастанию, -1 по убыванию

  const placeholderList = [
    {
      _id: "0",
      service: [
        { amountPerHour: 100, currency: "RUB" },
        { amountPerHour: 2, currency: "USDT" },
      ],
      goodReviewAmount: 10,
      badReviewAmount: 2,
    },
    {
      _id: "1",
      service: [
        { amountPerHour: 300, currency: "RUB" },
        { amountPerHour: 1, currency: "USDT" },
      ],
      goodReviewAmount: 5,
      badReviewAmount: 6,
    },
    {
      _id: "2",
      service: [
        { amountPerHour: 200, currency: "RUB" },
        { amountPerHour: 4, currency: "USDT" },
      ],
      goodReviewAmount: 6,
      badReviewAmount: 10,
    },
    {
      _id: "3",
      service: [{ amountPerHour: 160, currency: "RUB" }],
      goodReviewAmount: 7,
      badReviewAmount: 11,
    },
    {
      _id: "4",
      service: [{ amountPerHour: 8, currency: "USDT" }],
      goodReviewAmount: 5,
      badReviewAmount: 1,
    },
  ];

  const localStorageWorkerList = localStorage.getItem(
    `easehow_workerList_${serviceName}`
  );
  const defaultWorkerList = localStorageWorkerList
    ? JSON.parse(localStorageWorkerList)
    : process.env.NODE_ENV === "development"
    ? placeholderList
    : [];
  const [workerList, setWorkerList] = useState(defaultWorkerList);

  useEffect(() => {
    (async () => {
      let responce = await getWorkerList(serviceName, 100, 0, languageCode);
      if (responce.serviceWorkerList && responce.serviceWorkerList.length > 0) {
        let resultList = sortWorkerList({
          sortValue: currentSortValue,
          sortDirection: 1,
          targetWorkerList: checkForWrongPrice(responce.serviceWorkerList),
          toggleDirection: false,
        })();
        setWorkerList(resultList);
        localStorage.setItem(
          `easehow_workerList_${serviceName}`,
          JSON.stringify(resultList.slice(0, 5))
        );
      } else {
        sortWorkerList({
          sortValue: currentSortValue,
          sortDirection: 1,
          targetWorkerList: placeholderList.map((a) => a),
          toggleDirection: false,
        })();
      }
    })();
  }, []);

  // #TODO костыль, исправить на стороне бэка
  function checkForWrongPrice(workerList) {
    let resultList = workerList.map((worker) => {
      worker.service = worker.service.filter((service) => {
        let n = service.amountPerHour;
        return Number(n) === n;
      });
      return worker;
    });
    return resultList;
  }

  function handleWorkerCardClick(workerData) {
    return () => {
      navigate(`/worker`, {
        state: {
          prevState: state,
          workerData: workerData,
          serviceName: serviceName,
        },
      });
    };
  }

  function sortWorkerList({
    sortValue,
    sortDirection,
    targetWorkerList,
    toggleDirection,
  }) {
    return function () {
      console.log("sorterData", {
        sortValue: sortValue,
        currentSortValue: currentSortValue,
        sortDirection: sortDirection,
        currentSortDirection: currentSortDirection,
        toggleDirection: toggleDirection,
      });

      let bottomValue = currentSortDirection * Infinity;
      bottomValue *= toggleDirection ? -1 : 1;
      bottomValue *= currentSortValue !== sortValue ? -1 : 1;
      targetWorkerList.sort((a, b) => {
        let aValue = 0;
        let bValue = 0;

        // if (sortValue === "fiat") {
        //   aValue =
        //     a.service.find((price) => price.currency === "RUB")
        //       ?.amountPerHour ?? bottomValue;
        //   bValue =
        //     b.service.find((price) => price.currency === "RUB")
        //       ?.amountPerHour ?? bottomValue;
        //   setCurrentSortValue("fiat");
        // }
        if (sortValue === "cript") {
          aValue =
            a.service.find((price) => price.currency === "USDT")
              ?.amountPerHour ?? bottomValue;
          bValue =
            b.service.find((price) => price.currency === "USDT")
              ?.amountPerHour ?? bottomValue;
          if (currentSortValue !== "cript") {
            setCurrentSortValue("cript");
          }
        }
        if (sortValue === "rate") {
          aValue =
            a.goodReviewAmount / (a.goodReviewAmount + a.badReviewAmount);
          bValue =
            b.goodReviewAmount / (b.goodReviewAmount + b.badReviewAmount);
          if (currentSortValue !== "rate") {
            setCurrentSortValue("rate");
          }
        }
        if (sortValue === "dealAmount") {
          aValue = a.goodReviewAmount + a.badReviewAmount;
          bValue = b.goodReviewAmount + b.badReviewAmount;
          if (currentSortValue !== "rate") {
            setCurrentSortValue("rate");
          }
        }

        let result;
        if (currentSortValue !== sortValue) {
          sortDirection = 1;
        } else if (toggleDirection) {
          sortDirection *= -1;
        }
        if (currentSortDirection !== sortDirection) {
          setCurrentSortDirection(sortDirection);
        }

        if (sortDirection > 0) {
          result = aValue - bValue > 0 ? 1 : -1;
        } else {
          result = aValue - bValue < 0 ? 1 : -1;
        }

        return result;
      });

      return targetWorkerList;
    };
  }

  const acc = "↑";
  const dec = "↓";
  const sortIconSymbol = currentSortDirection < 0 ? acc : dec;
  let sortIcon = {
    fiat: currentSortValue === "fiat" ? sortIconSymbol : "",
    cript: currentSortValue === "cript" ? sortIconSymbol : "",
    rate: currentSortValue === "rate" ? sortIconSymbol : "",
    dealAmount: currentSortValue === "dealAmount" ? sortIconSymbol : "",
  };

  return (
    <div className="container">
      <div className="header">
        <h1>
          {serviceName}
          <p className={styles.textPricePerHour}>{textPricePerHour}</p>
        </h1>

        <ul
          className={`${
            workerList.length > 1 ? styles.filter_list : "dynamic_display_none"
          }`}
        >
          {/* <li>
            <button
              onClick={sortWorkerList({
                sortValue: "fiat",
                sortDirection: currentSortDirection,
                targetWorkerList: workerList,
                toggleDirection: true,
              })}
            >
              {textFiat} {sortIcon.fiat}
            </button>
          </li> */}
          <li>
            <button
              onClick={sortWorkerList({
                sortValue: "cript",
                sortDirection: currentSortDirection,
                targetWorkerList: workerList,
                toggleDirection: true,
              })}
            >
              {textCript} {sortIcon.cript}
            </button>
          </li>
          <li>
            <button
              onClick={sortWorkerList({
                sortValue: "rate",
                sortDirection: currentSortDirection,
                targetWorkerList: workerList,
                toggleDirection: true,
              })}
            >
              {textRate} {sortIcon.rate}
            </button>
          </li>
          <li>
            <button
              onClick={sortWorkerList({
                sortValue: "dealAmount",
                sortDirection: currentSortDirection,
                targetWorkerList: workerList,
                toggleDirection: true,
              })}
            >
              {textDeals} {sortIcon.dealAmount}
            </button>
          </li>
        </ul>
      </div>
      <ul className={styles.worker_list}>
        {sortWorkerList({
          sortValue: currentSortValue,
          sortDirection: currentSortDirection,
          targetWorkerList: workerList,
          toggleDirection: false,
        })().map((worker, i) => {
          let rate =
            worker.goodReviewAmount /
            (worker.goodReviewAmount + worker.badReviewAmount);
          let dealAmount = worker.goodReviewAmount + worker.badReviewAmount;
          return (
            <li key={worker._id} onClick={handleWorkerCardClick(worker)}>
              <WorkerCard
                name={worker.nickname}
                priceList={worker.service}
                rate={rate}
                dealAmount={dealAmount}
                selectedSortValue={currentSortValue}
                isClickable={true}
                desrciptionData={worker.description}
              ></WorkerCard>
            </li>
          );
        })}
      </ul>

      <NavBar
        currentPagePath={"/list"}
        currentPathState={state}
        prevPagePath={"/"}
      />
    </div>
  );
};

export default WorkerListPage;
